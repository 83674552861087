import cloneDeep from 'lodash/cloneDeep'
import { LazyCollectionRequestNew } from '#components'

export function useStatusMap() {
  const { $i18n } = useNuxtApp()

  return new Map<string, { title: string; color: 'yellow' | 'green' | 'dark' | 'red' }>([
    ['SCHEDULED', { title: $i18n.t('generic.scheduled'), color: 'dark' }],
    ['DONE', { title: $i18n.t('generic.done'), color: 'green' }],
    ['CANCELLED', { title: $i18n.t('generic.canceled'), color: 'red' }],
  ])
}

export const useCollectionProgress = (status: string) => {
  switch (status) {
    case 'SCHEDULED':
      return 50
    case 'DONE':
      return 100
    case 'CANCELLED':
      return 100
    default:
      return 0
  }
}

export const collectionRequestData = reactive({
  loading: false,
  collectionRequests: [] as CollectionRequestItem[],
  total: 0,
})

export const collectionRequestReload = ref<boolean>(false)

export const sortCollectionRequest = ref<{ key: string; direction: string }>({
  key: 'date',
  direction: 'DESC',
})

export function useCollectionRequestTypesMap() {
  const { $i18n } = useNuxtApp()

  return {
    MANUAL: $i18n.t('generic.pickup_types.ondemand'),
    SCHEDULE: $i18n.t('generic.pickup_types.regular'),
    ORDER_SUBMIT: $i18n.t('generic.pickup_types.ondemand'),
  }
}

export const collectionRequestInitialListFilters: CollectionRequestFilter = reactive({
  date: {
    from: null,
    to: null,
  },
  sender: null,
  carrierCode: null,
  type: null,
  status: null,
})

export const collectionRequestListFilters = reactive<CollectionRequestFilter>(
  cloneDeep(collectionRequestInitialListFilters),
)

export const fetchCollectionRequests = async (limit?: number) => {
  const { $api, $i18n } = useNuxtApp()
  const { listPagination } = useListPagination()
  limit ??= listPagination.limit

  collectionRequestData.loading = true
  collectionRequestData.collectionRequests = []

  try {
    const sortQuery =
      sortCollectionRequest.value.key.length > 0
        ? `&order=${sortCollectionRequest.value.key}&direction=${sortCollectionRequest.value.direction}`
        : ''

    const { data } = await $api.post(
      `/user/collection-request/list?limit=${limit}&offset=${listPagination.offset}${sortQuery}`,
      collectionRequestListFilters,
    )
    collectionRequestData.collectionRequests = data.list
    collectionRequestData.total = data.totalCount
  } catch (e) {
    console.error($i18n.t('generic.fetch_error') + ':', e)
    // TODO: proper error handling
  } finally {
    collectionRequestData.loading = false
  }
}

export const repeatCollectionRequest = (item: CollectionRequestItem) => {
  const { $i18n } = useNuxtApp()
  const modal = openModal(
    LazyCollectionRequestNew,
    {
      title: $i18n.t('components.collection_request.repeat_request'),
      disabled: true,
      addressId: item.contact.id,
      carrier: item.carrierCode,
      focusDate: true,
      isSchedule: item.type === 'SCHEDULE',
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        collectionRequestReload.value = true
      },
    },
    { title: 'PlanCollectionRequest' },
  )
}

export const planCollectionRequest = () => {
  const router = useRouter()
  const modal = openModal(
    LazyCollectionRequestNew,
    {
      async onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        router.push('/pickups/plan')
        collectionRequestReload.value = true
      },
    },
    { title: 'PlanCollectionRequest' },
  )
}

export const createRegularCollectionRequest = (item: CollectionRequestItem) => {
  const { $i18n } = useNuxtApp()

  const modal = openModal(
    LazyCollectionRequestNew,
    {
      title: $i18n.t('components.collection_request.regular_collection_create'),
      addressId: item.contact.id,
      carrier: item.carrierCode,
      isSchedule: true,
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        collectionRequestReload.value = true
      },
    },
    { title: 'PlanCollectionRequest' },
  )
}

export const changeCollectionRequest = (item: CollectionRequestItem) => {
  const { $i18n } = useNuxtApp()

  const modal = openModal(
    LazyCollectionRequestNew,
    {
      title: $i18n.t('components.collection_request.edit_collection_request'),
      id: item.id,
      addressId: item.contact.id,
      carrier: item.carrierCode,
      pickupDate: item.date,
      isSchedule: item.type === 'SCHEDULE',
      disabled: item.type === 'SCHEDULE',
      focusDate: true,
      onClose() {
        modal.close()
      },
      onDone() {
        modal.close()
        collectionRequestReload.value = true
      },
    },
    { title: 'PlanCollectionRequest' },
  )
}

export const deleteCollectionRequest = async (item: CollectionRequestItem) => {
  const { $api, $i18n } = useNuxtApp()

  collectionRequestData.loading = true
  try {
    const conf = await openConfirm('', $i18n.t('components.collection_request.regular_collection_delete_info'))

    if (conf) {
      await $api.post(`/user/collection-request/cancel?id=${item.id}`)
      collectionRequestReload.value = true
    }
  } catch (e) {
    console.error('Error during cancelling collection request:', e)
    // TODO: proper error handling
  } finally {
    collectionRequestData.loading = false
  }
}

export const itemCollectionDetailData = ref<CollectionRequestItem>()

export const fetchCollectionDetail = async (item: CollectionRequestItem) => {
  return item ? (itemCollectionDetailData.value = item) : false
}

export function useActionListCollection() {
  const { $i18n } = useNuxtApp()

  return new Map([
    [
      'MANUAL',
      new Map([
        [
          'SCHEDULED',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
            {
              title: $i18n.t('components.collection_request.regular_collection_create'),
              action: createRegularCollectionRequest,
            },
            { title: $i18n.t('components.collection_request.change'), color: 'red', action: changeCollectionRequest },
            { title: $i18n.t('components.collection_request.delete'), color: 'red', action: deleteCollectionRequest },
          ],
        ],
        [
          'DONE',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
            {
              title: $i18n.t('components.collection_request.regular_collection_create'),
              action: createRegularCollectionRequest,
            },
          ],
        ],
        [
          'CANCELLED',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
            {
              title: $i18n.t('components.collection_request.regular_collection_create'),
              action: createRegularCollectionRequest,
            },
          ],
        ],
      ]),
    ],
    [
      'ORDER_SUBMIT',
      new Map([
        [
          'SCHEDULED',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
            {
              title: $i18n.t('components.collection_request.regular_collection_create'),
              action: createRegularCollectionRequest,
            },
            { title: $i18n.t('components.collection_request.change'), color: 'red', action: changeCollectionRequest },
            { title: $i18n.t('components.collection_request.delete'), color: 'red', action: deleteCollectionRequest },
          ],
        ],
        [
          'DONE',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
            {
              title: $i18n.t('components.collection_request.regular_collection_create'),
              action: createRegularCollectionRequest,
            },
          ],
        ],
        [
          'CANCELLED',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
            {
              title: $i18n.t('components.collection_request.regular_collection_create'),
              action: createRegularCollectionRequest,
            },
          ],
        ],
      ]),
    ],
    [
      'SCHEDULE',
      new Map([
        [
          'SCHEDULED',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.change'), action: changeCollectionRequest },
            { title: $i18n.t('components.collection_request.delete'), color: 'red', action: deleteCollectionRequest },
          ],
        ],
        [
          'DONE',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
          ],
        ],
        [
          'CANCELLED',
          [
            {
              title: $i18n.t('components.collection_request.detail'),
              action: fetchCollectionDetail,
              hideAsButton: true,
            },
            { title: $i18n.t('components.collection_request.repeat_request'), action: repeatCollectionRequest },
          ],
        ],
      ]),
    ],
  ])
}
